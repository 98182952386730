@import '../../styles/mixins/global.scss';

.blog-banner-style {
    background-image: url("../../assets/images/contact-banner.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    margin: 76px 0 0 0;
    background-position: center;
    height: 400px;
    @include flex-center;
    justify-content: center;
    position: relative;

    .page-title {
        position: relative;

        h1 {
            @include heading-1;
            color: var(--white-color);
            text-align: center;
            margin: 0 0 24px 0;
        }
    }

    &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: "";
        background-color: rgba(0, 0, 0, 0.5);
    }
}

.blog-details-content-alignment {
    padding: 90px 0;

    @include breakpoint("max-md") {
        padding: 60px 0;
    }

    .page-title {
        padding: 0 0 80px 0;

        h1 {
            @include heading-1;
            color: var(--black-3);
            margin: 0 0 24px 0;
            text-align: center;
        }

        p {
            @include text;
            text-align: center;
            color: var(--black-4);
            max-width: calc(750px);
            margin: 0 auto;
        }
    }

    .grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 40px;

        .grid-items {
            .card-details {
                padding: 25px 0 0 0;

                h3 {
                    @include heading-3;
                    color: var(--black-3);
                    margin: 0 0 15px 0;
                }

                p {
                    @include text;
                    color: var(--black-4);
                    margin: 0 0 14px 0;
                }

                span {
                    @include text-sm;
                    @include font-bold;
                    color: var(--green-color);
                    display: block;
                    cursor: pointer;
                }
            }

            .card-image {
                img {
                    width: 100%;
                    height: 350px;
                    object-fit: cover;
                    border-radius: 8px;
                }
            }
        }
    }
}