.loader-wrapper {
    display: flex;
    /* background-color: #242f32; */
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgb(245 245 245 / 50%);
    backdrop-filter: blur(4px);
}

.loader {
    display: inline-block;
    width: 40px;
    height: 40px;
    position: relative;
    border: 4px solid rgb(98, 125, 237);
    animation: loader 2s infinite ease;
    border-radius: 5px;
}

.loader-inner {
    vertical-align: top;
    display: inline-block;
    width: 100%;
    background-color: rgb(98, 125, 237);
    animation: loader-inner 2s infinite ease-in;
}

@keyframes loader {
    0% {
        transform: rotate(0deg) scale(1.1);
    }
    25% {
        transform: rotate(180deg);
    }
    50% {
        transform: rotate(180deg) scale(1.2);
    }
    75% {
        transform: rotate(360deg);
    }
    100% {
        transform: rotate(360deg) scale(1.1);
    }
}

@keyframes loader-inner {
    0% {
        height: 0%;
    }
    25% {
        height: 0%;
    }
    50% {
        height: 100%;
    }
    75% {
        height: 100%;
    }
    100% {
        height: 0%;
    }
}


/* 2nd */

.lds-roller {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding-left: 10px;
}

.Loader-image {
    height: 26px;
    width: 26px;
    min-height: 20px;
    min-width: 20px;
    animation: anim 1s linear infinite;
}

@keyframes anim {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}