body {
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
}

* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

a {
  text-decoration: none;
}

h1,h2,h3,h4,h5,h6,p {
  margin: 0;
}

p:last-child {
  margin: 0;
}