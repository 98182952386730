@import '../../../styles/mixins/global.scss';

.our-team-section-alignment {
    padding: 80px 0;

    @include breakpoint("max-md") {
        padding: 60px 0;
    }

    .left-right-content-alignment {
        padding: 0 250px;

        @include breakpoint(max-md) {
            padding: 100px;
        }

        @include breakpoint(max-sm) {
            padding: 0;
        }

        .grid {
            display: grid;
            grid-template-columns: 250px 1fr;
            gap: 60px;
            align-items: center;

            @include breakpoint(max-md) {
                grid-template-columns: 1fr;
            }

            @include breakpoint("max-sm") {
                gap: 40px;
            }

            .grid-items {
                .profile-image-style {
                    @include breakpoint("max-sm") {
                        @include flex-center;
                        justify-content: center;
                    }

                    img {
                        width: 250px;
                        height: 250px;
                        border-radius: 50%;
                        object-fit: cover;
                    }
                }

                h4 {
                    @include heading-2;
                    color: var(--black-3);
                    margin: 0 0 24px 0;

                    @include breakpoint("max-sm") {
                        text-align: center;
                        margin: 0 0 20px 0;
                    }
                }

                .fill-button {
                    padding: 40px 0 0 0;

                    @include breakpoint("max-sm") {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 20px 0 0 0;
                    }
                }

                p {
                    @include text;

                    @include breakpoint("max-sm") {
                        text-align: center;
                    }

                    color: var(--black-4);
                }
            }
        }
    }
}