@import "../../../styles/mixins/global.scss";
.product-details-alignemt {
    ul {
        margin: 0;
        padding: 0;
        li {
            font-size: 14px;
            line-height: 22px;
        }
    }
    .product-name {
        h4 {
            font-family: "Inter", sans-serif;
            font-size: 2rem;
            font-weight: 600;
            line-height: 1.5;
            color: var(--black-3);
            font-weight: 500;
            margin: 0 0 16px 0;
        }
    }
    .product-details {
        p {
            font-family: "Inter", sans-serif;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            letter-spacing: 0.02em;
            color: var(--black-4);
            // max-width: 600px;
            margin: 0 0 16px 0;
            text-align: justify;
        }
    }
    .price-tag-alignment {
        @include flex-center;
        gap: 10px;
        margin: 0 0 10px 0;
        .title {
            span {
                font-family: "Inter", sans-serif;
                font-size: 1rem;
                font-weight: 500;
                line-height: 1.5;
                color: var(--black-3);
                font-weight: 500;
                margin: 0 0 16px 0;
            }
        }
        .price-alignment {
            span {
                font-family: "Inter", sans-serif;
                font-size: 1rem;
                font-weight: 400;
                line-height: 1.5;
                letter-spacing: 0.02em;
                color: var(--black-4);
            }
            a {
                font-family: "Inter", sans-serif;
                font-size: 1rem;
                font-weight: 400;
                line-height: 1.5;
                letter-spacing: 0.02em;
                color: var(--green-color);
            }
            .sub-details-alignment {
                span {
                    font-family: "Inter", sans-serif;
                    font-size: 1rem;
                    font-weight: 400;
                    line-height: 1.5;
                    letter-spacing: 0.02em;
                    color: var(--black-4);
                }
            }
        }
    }
    .atrribute-tag-alignment {
        .title {
            span {
                font-family: "Inter", sans-serif;
                font-size: 1rem;
                font-weight: 500;
                line-height: 1.5;
                color: var(--black-3);
                font-weight: 500;
                margin: 0 0 16px 0;
            }
        }
        .price-alignment {
            @include flex;
            flex-direction: column;
            gap: 5px;
            padding: 12px 0 0 0;
            .sub-details-alignment {
                span {
                    font-family: "Inter", sans-serif;
                    font-size: 0.9rem;
                    font-weight: 500;
                    color: var(--black-3);
                    line-height: 1.5;
                    letter-spacing: 0.02em;
                }
            }
        }
    }
}

.assign-modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    @include flex-center;
    justify-content: center;
    z-index: 999;
    .assign-modal-box {
        width: 850px;
        height: calc(100vh - 150px);
        overflow: auto;
        background-color: #fff;
        border-radius: 6px;
        table {
            width: 100%;
            border-collapse: collapse;
            tr {
                border: 1px solid #d0d0d0;
            }
            td {
                border: 1px solid #d0d0d0;
                padding: 10px;
                font-size: 14px;
            }
            th {
                padding: 10px;
                font-size: 14px;
                border: 1px solid #d0d0d0;
            }
        }
        ul {
            margin: 0;
            padding: 0 1.25rem 0 1.25rem;
            li {
                font-size: 14px;
                padding: 0 0 10px 0;
            }
        }
        .modal-body {
            padding: 20px;
            .form-control {
                padding: 0 0 20px 0;
            }
            .blue-button {
                @include flex-center;
                justify-content: center;
                padding: 20px 0 0 0;
            }
        }
        .modal-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 20px;
            position: sticky;
            top: 0;
            z-index: 999;
            background: #fff;
            border-bottom: 1px solid #d0d0d0;
            i {
                font-size: 20px;
                color: var(--gray-6);
                @include pointer;
            }
            h1 {
                @include heading-4;
                color: var(--dark-black);
                margin: 0;
            }
        }
    }
}