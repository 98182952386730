@mixin font-normal {
    font-weight: 400;
}

@mixin font-medium {
    font-weight: 500;
}

@mixin font-bold {
    font-weight: 600;
}