@import '../../../styles/mixins/global.scss';

.our-benefits-content-banner-alignment {
    background-image: url("../../../assets/images/green-banner.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    padding: 90px 0;

    @include breakpoint("max-md") {
        padding: 60px 0;
    }

    @include breakpoint(max-sm) {
        padding: 60px 0;
    }

    .grid {
        display: grid;
        grid-template-columns: 420px 1fr;
        gap: 120px;

        @include breakpoint(max-md) {
            grid-template-columns: 1fr;
            gap: 20px;

        }

        @include breakpoint(max-sm) {
            gap: 20px;
        }

        .grid-items {
            @include breakpoint(max-md) {
                text-align: center;
            }

            span {
                @include heading-1;
                font-weight: 500;
                color: var(--white-color);
                padding: 0 0 16px 0;
                display: block;
            }

            .image-style {
                padding: 60px 0 0 0;

                @include breakpoint("max-sm") {
                    padding: 0 0 0 0;
                }

                img {
                    width: calc(100% - 0%);
                    object-fit: cover;
                    height: 450px;

                    @include breakpoint(max-sm) {
                        height: 250px;
                    }
                }
            }

            .text-style {
                padding: 0 0 60px 0;

                @include breakpoint("max-md") {
                    padding: 0;
                }

                @include breakpoint("max-sm") {
                    padding: 0;
                }

                p {
                    @include text;
                    color: var(--white-color);
                    margin: 0 0 24px 0;
                    text-align: justify;

                    @include breakpoint("max-sm") {
                        text-align: justify;
                    }
                }

                p:last-child {
                    margin: 0;
                }
            }

            h1 {
                @include heading-4;
                color: var(--white-color);
                font-weight: 400;
                line-height: 45px;
                margin: 0 0 30px 0;
            }
        }
    }
}

.home-page-gallery-section {
    padding: 90px 0;

    @include breakpoint("max-sm") {
        padding: 60px 0;
    }

    .page-title {
        padding: 0 0 80px 0;

        @include breakpoint("max-sm") {
            padding: 0 0 40px 0;
        }

        h1 {
            text-align: center;
            @include heading-1;
            color: var(--black-3);

            @include breakpoint("max-sm") {
                @include heading-2;
            }
        }
    }

    .fill-button {
        @include flex-center;
        justify-content: center;
        padding: 60px 0 0 0;

        @include breakpoint("max-sm") {
            padding: 40px 0 0 0;
        }
    }

    .three-col-grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;

        @include breakpoint("max-md") {
            grid-template-columns: repeat(2, 1fr);
        }

        @include breakpoint("max-sm") {
            grid-template-columns: repeat(1, 1fr);
        }

        img {
            width: 100%;
            border-radius: 6px;
            height: 320px;
            cursor: pointer;
            object-fit: cover;
        }
    }
}

.quality-cation-all-content-alignment {
    padding: 0 0 90px 0;

    @include breakpoint("max-sm") {
        padding: 0 0 60px 0;
    }

    .page-title {
        padding: 0 0 80px 0;

        @include breakpoint("max-sm") {
            padding: 0 0 40px 0;
        }

        span {
            display: block;
            @include text-sm;
            color: var(--green-color);
            padding: 0 0 8px 0;
            text-align: center;
        }

        h1 {
            @include heading-2;
            text-align: center;
            color: var(--black-3);

            @include breakpoint("max-sm") {
                @include heading-3;
            }
        }
    }

    .grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 30px;

        @include breakpoint("max-md") {
            grid-template-columns: repeat(2, 1fr);
            gap: 20px;
        }

        @include breakpoint("max-sm") {
            grid-template-columns: repeat(1, 1fr);
            gap: 20px;
        }

        .grid-items {
            border: 1px solid #d0d0d0;
            border-radius: 4px;
            cursor: pointer;

            .card-image {
                img {
                    width: 100%;
                    height: 250px;
                    object-fit: cover;
                    border-radius: 4px;
                }
            }

            .card-details {
                padding: 16px;
                border-top: 1px solid #d0d0d0;

                span {
                    @include text-md;
                    font-weight: 500;
                    color: var(--black-4);
                    display: block;
                    text-align: center;
                }
            }
        }
    }
}