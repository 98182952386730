@import "../../styles/mixins/global.scss";
.contanct-banner {
    background-image: url("../../assets/images/contact-banner.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    margin: 76px 0 0 0;
    background-position: center;
    height: 400px;
    @include flex-center;
    justify-content: center;
    position: relative;
    .page-title {
        position: relative;
        h1 {
            @include heading-1;
            color: var(--white-color);
            text-align: center;
            margin: 0 0 24px 0;
        }
    }
    &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: "";
        background-color: rgba(0, 0, 0, 0.5);
    }
}

.contact-information-content-alignment {
    padding: 100px 0 90px 0;
    background-color: var(--light-banner);
    @include breakpoint("max-md") {
        padding: 60px 0 60px 0;
    }
    @include breakpoint("max-sm") {
        padding: 60px 0;
    }
    .grid {
        display: grid;
        grid-template-columns: 568px 1fr;
        grid-gap: 0 90px;
        gap: 0 90px;
        @include breakpoint(max-md) {
            grid-template-columns: 1fr;
        }
        iframe {
            width: 100%;
            height: 450px;
            object-fit: cover;
            @include breakpoint("max-sm") {
                height: 350px;
            }
        }
        .grid-items {
            @include breakpoint(max-md) {
                &:last-child {
                    margin: 40px 0 0 0;
                    @include breakpoint("max-sm") {
                        margin: 20px 0 0 0;
                    }
                }
            }
            .logo-alignment {
                padding: 0 0 40px 0;
                img {
                    max-width: 180px;
                    cursor: pointer;
                }
            }
            .fill-button {
                padding: 20px 0 0 0;
            }
            h1 {
                @include heading-3;
                max-width: calc(100% - 25%);
                color: var(--green-color);
                @include breakpoint(max-sm) {
                    max-width: 100%;
                    text-align: center;
                }
            }
            h2 {
                @include heading-1;
                color: var(--black-3);
                max-width: calc(100% - 25%);
                margin: 0 0 20px 0;
                @include breakpoint(max-sm) {
                    max-width: 100%;
                    margin: 0 0 60px 0;
                    text-align: center;
                }
            }
            .input {
                padding: 0 0 24px 0;
                input {
                    width: 100%;
                    height: 40px;
                    background-color: transparent;
                    @include text-sm;
                    border: none;
                    border-bottom: 1px solid var(--black-3);
                    color: var(--black-3);
                    &:focus {
                        outline: none;
                    }
                }
                textarea {
                    width: 100%;
                    height: 120px;
                    background-color: transparent;
                    @include text-sm;
                    border: none;
                    border-bottom: 1px solid var(--black-3);
                    color: var(--black-3);
                    &:focus {
                        outline: none;
                    }
                }
                 ::placeholder {
                    @include text-sm;
                    color: var(--black-3);
                }
            }
            .input-grid {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 24px;
                @include breakpoint(max-sm) {
                    grid-template-columns: 1fr;
                }
                .input-grid-items {
                    .input {
                        &.contry-code-alignment {
                            display: flex;
                            align-items: center;
                            gap: 10px;
                            select {
                                background: transparent;
                                border: none;
                                border-bottom: 1px solid var(--black-3);
                                height: 40px;
                                width: 35%;
                                &:focus {
                                    outline: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.spanError {
    color: red;
    top: 5px;
    font-size: 14px;
}

.map-image {
    p {
        font-size: 60px;
        color: #ffff;
        text-align: center;
    }
}