@import '../../styles/mixins/global.scss';
header {
    padding: 16px 0;
    background-color: var(--white-color);
    box-shadow: 0 2px 5px 0 #0000001a;
    border-bottom: 1px solid #d0d0d0;
    position: sticky;
    top: 0;
    width: 100%;
    left: 0;
    z-index: 999;
    .header-alignment {
        @include flex-center;
        justify-content: space-between;
        .logo {
            img {
                max-width: 215px;
                object-fit: contain;
                height: 55px;
                cursor: pointer;
                @include pointer;
            }
        }
        .menu {
            @include flex-center;
            @include breakpoint(max-md) {
                display: none;
            }
            a:last-child {
                margin: 0;
            }
            a {
                display: block;
                @include pointer;
                @include text;
                color: var(--black-4);
                position: relative;
                margin-right: 40px;
                &:hover {
                    color: var(--green-color);
                    &::after {
                        transform: scaleX(1);
                        opacity: 1;
                    }
                }
                &::after {
                    content: "";
                    position: absolute;
                    height: 2px;
                    width: 100%;
                    bottom: -8px;
                    left: 0;
                    background-color: var(--green-color);
                    -webkit-transform: scaleX(0);
                    transform: scaleX(0);
                    -webkit-transform-origin: left;
                    transform-origin: left;
                    opacity: 0;
                    transition: opacity .3s ease-out, -webkit-transform .3s ease-out;
                    transition: transform .3s ease-out, opacity .3s ease-out;
                    transition: transform .3s ease-out, opacity .3s ease-out, -webkit-transform .3s ease-out;
                }
            }
        }
        .mobile-menu {
            display: none;
            @include breakpoint(max-md) {
                display: block;
            }
            img {
                width: 30px;
                height: 30px;
                @include pointer;
            }
        }
    }
}

.contact-blur {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    @include flex-center;
    justify-content: center;
    .contact-modal {
        width: 700px;
        background-color: #fff;
        border-radius: 10px;
        .modal-body {
            padding: 24px;
        }
        .input-grid {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 20px;
        }
        .text-area-top {
            padding: 20px 0 0 0;
        }
        .fill-button {
            @include flex-center;
            justify-content: center;
            padding: 24px 0 0 0;
        }
        .input {
            label {
                @include text-sm;
                color: #777;
                display: block;
                padding: 0 0 6px 0;
            }
            input {
                width: 100%;
                height: 45px;
                border: 1px solid #d0d0d0;
                padding: 0 16px;
                border-radius: 4px;
                @include text-sm;
                &.number-input {
                    padding: 0 !important;
                }
                &:focus {
                    outline: none;
                }
            }
            textarea {
                width: 100%;
                height: 110px;
                border: 1px solid #d0d0d0;
                padding: 16px;
                border-radius: 4px;
                resize: none;
                @include text-sm;
                &:focus {
                    outline: none;
                }
            }
        }
        .modal-title {
            padding: 0 0 40px 0;
            h1 {
                @include heading-3;
                margin: 0 0 20px 0;
                color: var(--black-3);
            }
            p {
                @include text-sm;
                color: var(--black-4);
                max-width: calc(100% - 5%);
            }
        }
        .modal-header {
            padding: 24px;
            border-bottom: 1px solid #d0d0d0;
            @include flex-center;
            justify-content: space-between;
            img {
                max-width: 195px;
            }
            i {
                color: #505050;
                font-size: 25px;
                @include pointer;
            }
        }
    }
}

.Loader-image {}

.spanError {
    color: red;
    top: 5px;
    font-size: 14px;
}

.header-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.5);
}

.mobile-view-sidebar-design {
    width: calc(100% - 100px);
    background-color: #fff;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 999;
    height: 100vh;
    .mobile-view-body-content-alignment {
        padding: 20px;
        a:first-child {
            padding-top: 0px;
        }
        a {
            display: block;
            @include text;
            font-size: 16px;
            font-weight: 500;
            border-bottom: 1px solid #d0d0d0;
            color: var(--black-4);
            padding: 15px 0;
        }
    }
    .mobile-view-header-alignment {
        padding: 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #d0d0d0;
        div {
            i {
                font-size: 20px;
                color: var(--green-color);
                @include pointer;
            }
            img {
                max-width: 45px;
                @include breakpoint("max-sm") {
                    max-width: 145px;
                }
            }
        }
    }
}

.mobile-view-sidebar-design-hidden {
    transform: translateX(100%);
    overflow: hidden;
    transition: .3s ease-in-out;
}

.mobile-view-sidebar-design-show {
    transform: translateX(0%);
    overflow: hidden;
    transition: .3s ease-in-out;
}

.react-tel-input {
    input {
        padding: 18.5px 14px 18.5px 60px !important;
    }
}