@import '../../../styles/mixins/global.scss';
.market-section-banner-alignment {
    background-color: var(--light-banner);
    padding: 90px 0;
    @include breakpoint("max-md") {
        padding: 160px 0 60px 0;
    }
    // @include breakpoint(max-sm) {
    //     padding: 60px 0;
    // }
    .grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 120px;
        align-items: center;
        @include breakpoint(max-md) {
            grid-template-columns: 1fr;
        }
        @include breakpoint(max-sm) {
            gap: 60px;
        }
        .grid-items {
            .image-style {
                img {
                    max-width: 100%;
                    width: 100%;
                    height: 750px;
                    object-fit: cover;
                    @include breakpoint(max-md) {
                        height: 400px;
                    }
                    @include breakpoint(max-sm) {
                        height: 480px;
                        object-position: top;
                    }
                }
            }
            .fill-button {
                padding: 10px 0 0 0;
            }
            .content-style {
                @include breakpoint(max-md) {
                    text-align: center
                }
                @include breakpoint("max-sm") {
                    .divider-title {
                        margin: 15px auto 15px auto !important;
                    }
                }
                h6 {
                    @include heading-1;
                    display: block;
                    padding: 0 0 0 0;
                    color: var(--green-color);
                }
                p {
                    @include text-md;
                    max-width: calc(100% - 10%);
                    margin: 0 0 24px 0;
                    color: var(--black-4);
                    @include breakpoint(max-md) {
                        width: 100%;
                        max-width: 100%
                    }
                    @include breakpoint("max-sm") {
                        text-align: justify;
                    }
                }
                h3 {
                    @include heading-3;
                    text-transform: capitalize;
                    margin: 0 0 24px 0;
                    color: var(--black-3);
                    max-width: calc(100% - 35%);
                    @include breakpoint(max-md) {
                        width: 100%;
                        max-width: 100%
                    }
                    @include breakpoint("max-md") {
                        @include heading-4;
                    }
                }
            }
        }
    }
}

.all-service-contnet-alignment {
    padding: 80px 0;
    @include breakpoint("max-md") {
        padding: 60px 0;
    }
    .page-title {
        padding: 0 0 60px 0;
        &.service-alignment {
            padding: 0 0 60px 0;
        }
        @include breakpoint("max-md") {
            padding: 0 0 60px 0;
        }
        h2 {
            @include heading-1;
            color: var(--green-color);
            text-align: center;
            @include breakpoint("max-md") {
                @include heading-2;
            }
        }
    }
    .grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 60px 24px;
        @include breakpoint("max-md") {
            grid-template-columns: repeat(2, 1fr);
            gap: 20px;
        }
        @include breakpoint("max-sm") {
            grid-template-columns: repeat(1, 1fr);
            gap: 20px;
        }
        .grid-items {
            .sub-grid {
                display: grid;
                grid-template-columns: 1fr;
                gap: 20px;
                align-items: center;
                @include breakpoint(max-sm) {
                    grid-template-columns: 1fr;
                    gap: 14px;
                }
                .sub-grid-items {
                    .icon-design {
                        img {
                            width: 100%;
                            height: 260px;
                            border-radius: 4px;
                            object-fit: cover;
                        }
                    }
                    h1 {
                        @include heading-4;
                        color: var(--black-3);
                        margin: 0 0 6px 0;
                    }
                    p {
                        @include text-sm;
                        color: var(--black-4);
                        margin: 0;
                    }
                }
            }
        }
    }
}