@import '../.../../../styles/mixins/global.scss';

.page-top-alignment {
    width: 50px;
    height: 50px;
    position: fixed;
    bottom: 20px;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 9999;
    font-size: 22px;
    color: #fff;
    cursor: pointer;
    background-color: var(--green-color);
    border-radius: 4px;
}