@import '../../../styles/mixins/global.scss';

.new-section-content-alignmet {
    padding: 90px 0;

    @include breakpoint("max-md") {
        padding: 60px 0;
    }

    .page-title {
        padding: 0 0 60px 0;

        span {
            @include text-sm;
            color: var(--green-color);
            display: block;
            text-align: center;
            padding: 0 0 10px 0;
        }

        h1 {
            @include heading-1;
            text-align: center;
            color: var(--black-3);
        }
    }

    .grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 24px;

        .grid-items {
            .card-image {
                img {
                    width: 100%;
                    height: 260px;
                    border-radius: 6px;
                    object-position: center;
                    object-fit: cover;
                }
            }

            .card-details {
                padding: 24px 0 0 0;

                h3 {
                    @include heading-3;
                    color: var(--black-3);
                    margin: 0 0 16px 0;
                }

                a {
                    display: block;
                    padding: 16px 0 0 0;
                    @include text-sm;
                    cursor: pointer;
                    color: var(--green-color);
                }

                p {
                    @include text;
                    color: var(--black-4);
                    margin: 0;
                }
            }
        }
    }
}