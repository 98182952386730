@import '../../../styles/mixins/global.scss';

.best-service-provider-section-alignment {
    padding: 90px 0;
    background-color: var(--light-banner);

    @include breakpoint("max-md") {
        padding: 60px 0;
    }

    .text-grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 40px;

        @include breakpoint("max-md") {
            grid-template-columns: repeat(2, 1fr);
            gap: 20px;
        }

        @include breakpoint(max-sm) {
            grid-template-columns: 1fr;
            text-align: center;
        }

        .text-grid-items {
            h4 {
                @include heading-3;
                color: var(--black-3);
                font-weight: 500;
                margin: 0 0 16px 0;
            }

            p {
                @include text-sm;
                color: var(--black-4);
                margin: 0;
                max-width: calc(100% - 12%);

                @include breakpoint(max-sm) {
                    max-width: 100%;
                    text-align: justify;
                }
            }
        }
    }

    .page-title {
        padding: 0 0 100px 0;

        @include breakpoint("max-sm") {
            padding: 0 0 60px 0;
        }

        h1 {
            @include heading-1;
            color: var(--green-color);
            margin: 0 0 16px 0;
            text-align: center;

            @include breakpoint("max-md") {
                @include heading-2;

            }
        }

        p {
            @include text;
            color: var(--black-4);
            max-width: 750px;
            margin: 20px auto 0 auto;
            text-align: center;

            @include breakpoint("max-sm") {
                text-align: justify;
            }
        }
    }
}