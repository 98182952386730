@import '../../styles/mixins/global.scss';

.custom-loader-design {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99999999999;
    background-color: #fbfbfb;
    @include flex-center;
    justify-content: center;

    @include breakpoint("max-sm") {
        width: 100%;
        height: 100vh;
    }

    .logo-text-alignment {
        padding: 16px 0 0 0;
    }

    .logo-center-alignment,
    .logo-text-alignment {
        @include flex-center;
        justify-content: center;

        img {
            @include pointer;
            max-width: 450px;
        }
    }
}