// @use './mixins/breakpoint.scss';
// @use './mixins/typography.scss';
// @use './mixins/columns.scss';
// @use './function/columns.scss';
// @use './layout/container.scss';
// @use './mixins/index.scss';
@import '../function/columns.scss';
@import '../layout/container.scss';
@import '../mixins/columns.scss';
@import '../mixins/breakpoint.scss';
@import '../mixins/index.scss';
@import '../mixins/typography.scss';
@import '../mixins/flexbox.scss';
@import '../mixins/font.scss';
@import '../mixins/theme.scss';
@import '../mixins/button.scss';
body {
    overflow-x: hidden;
    font-family: 'Inter', sans-serif;
}

.image-gallery-fullscreen-button,
.image-gallery-play-button {
    @include breakpoint("max-sm") {
        display: none;
    }
}

.new-page-banner-service {
    background-image: url("../../assets/images/green.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    background-position: center center;
    padding: 100px 0;
    @include breakpoint("max-md") {
        padding: 60px 0;
    }
    .new-box-center-alignment {
        display: flex;
        justify-content: center;
        .new-box {
            width: 750px;
            padding: 40px 0;
            background: rgba(255, 255, 255, 0.8);
            @include breakpoint("max-md") {
                width: 100%;
            }
            h1 {
                @include heading-3;
                color: #000;
                text-align: center;
            }
            p {
                @include text-sm;
                color: #000;
                text-align: center;
                span {
                    color: var(--green-color);
                    font-weight: 500;
                    @include pointer;
                }
            }
        }
    }
}

.divider-title {
    background-color: #4BAF47;
    height: 2px;
    display: block;
    width: 100px;
    margin: 15px 0;
    position: relative;
    text-align: center;
    transition: all .3s ease-in-out;
    span {
        height: 2px;
        width: 70px;
        background-color: #4BAF47;
        position: absolute;
        bottom: -4px;
        display: block;
        content: "";
        z-index: 1;
        width: 70px;
        transition: all .3s ease-in-out;
        left: 15px;
    }
}

.divider-title-center {
    background-color: #4BAF47;
    height: 2px;
    display: block;
    width: 100px;
    margin: 15px auto 0 auto;
    position: relative;
    text-align: center;
    transition: all .3s ease-in-out;
    span {
        height: 2px;
        width: 70px;
        background-color: #4BAF47;
        position: absolute;
        bottom: -4px;
        display: block;
        content: "";
        z-index: 1;
        width: 70px;
        transition: all .3s ease-in-out;
        left: 15px;
    }
}

textarea {
    resize: none !important;
}

.pdf-download-button-center-alignment-for-page {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
}

.pdf-disable {
    opacity: 0.6 !important;
    cursor: not-allowed !important;
}