@import '../../styles/mixins/global.scss';
.footer-first-section-alignment {
    background-image: url("../../assets/images/theme.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    @include flex-center;
    justify-content: center;
    width: 100%;
    position: relative;
    padding: 120px 0;
    @include breakpoint("max-sm") {
        height: 400px;
    }
    &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        content: "";
    }
    .page-title {
        position: relative;
        .button-center-alignment {
            @include flex-center;
            justify-content: center;
        }
        h1 {
            @include heading-1;
            line-height: 70px;
            text-align: center;
            max-width: calc(100% - 20%);
            margin: 0 auto;
            color: var(--white-color);
            @include breakpoint(max-sm) {
                line-height: 45px;
                max-width: 100%;
                font-size: 28px;
            }
        }
    }
}

footer {
    background-color: var(--light-banner);
    padding: 60px 0 20px 0;
    @include breakpoint(max-sm) {
        padding: 60px 0 20px 0;
    }
    .copyright-content-alignment {
        padding: 20px 0 0 0;
        display: flex;
        align-items: center;
        justify-content: center;
        div+div {
            @include flex-center;
            a {
                margin-right: 25px;
            }
            a:last-child {
                margin: 0;
            }
        }
        div {
            p {
                @include text-sm;
                color: var(--black-4);
                margin: 0;
                cursor: pointer;
            }
            a {
                @include text-sm;
                color: var(--black-4);
                cursor: pointer;
            }
        }
    }
    .footer-grid {
        display: grid;
        grid-template-columns: 410px 1fr 1fr;
        padding: 0 0 40px 0;
        gap: 120px;
        border-bottom: 1px solid #d0d0d0;
        @include breakpoint(max-lg) {
            grid-template-columns: 1fr;
        }
        @include breakpoint(max-sm) {
            gap: 20px;
        }
        .footer-grid-items {
            .footer-contact-section {
                @include breakpoint(max-sm) {
                    margin: 20px 0 0 0;
                }
                .details-alignment:last-child {
                    padding: 0;
                }
                .details-alignment {
                    gap: 8px;
                    padding: 0 0 30px 0;
                    a {
                        text-align: center;
                        display: block;
                        padding: 11px 0 0 0 !important;
                        color: var(--green-color) !important;
                    }
                    .img-alignment {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        img {
                            max-width: 20px;
                        }
                        p {
                            padding-left: 10px;
                            @include text-sm;
                            color: var(--black-3);
                        }
                    }
                }
            }
            .footer-sub-title {
                h2 {
                    @include heading-4;
                    color: var(--black-3);
                    margin: 0 0 40px 0;
                    @include breakpoint("max-sm") {
                        text-align: center;
                        margin: 0 0 20px 0;
                    }
                }
            }
            .footer-menu-alignment {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                div {
                    @include breakpoint("max-sm") {
                        width: 100%;
                    }
                }
                a {
                    @include text-sm;
                    color: var(--black-4);
                    display: block;
                    text-transform: uppercase;
                    padding: 0 0 16px 0;
                    cursor: pointer;
                    @include breakpoint("max-sm") {
                        text-align: center;
                    }
                    &.number-alignment {
                        color: black;
                    }
                }
            }
            .logo {
                padding: 0 0 20px 0;
                @include breakpoint("max-sm") {
                    @include flex-center;
                    justify-content: center;
                }
                img {
                    max-width: 305px;
                    object-fit: contain;
                    height: 65px;
                    cursor: pointer;
                    @include pointer;
                }
            }
            .footer-child-text {
                p {
                    @include text-sm;
                    color: var(--black-4);
                }
            }
        }
    }
}

.footer-contact-section {
    .details-alignment {
        padding: 0 0 16px 0;
    }
    a {
        cursor: pointer;
        padding: 0 !important;
        color: var(--black-4) !important;
    }
}

.footer-company-location {
    padding: 30px 0 0 0;
    p {
        @include text;
        color: var(--black-4);
        @include breakpoint("max-sm") {
            text-align: center;
        }
    }
    h5 {
        @include heading-4;
        color: var(--green-color);
        margin: 0 0 10px 0;
        @include breakpoint("max-sm") {
            text-align: center;
        }
    }
}

.asas {
    font-family: "Inter", sans-serif;
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.5;
    letter-spacing: 0.02em;
    color: var(--black-3);
    margin: 0 0 40px 0;
    text-align: center;
}