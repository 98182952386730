.fill-button {
    button {
        background-color: var(--green-color);
        color: var(--white-color);
        padding: 12px 24px;
        cursor: pointer;
        border: none;
        @include text;
        font-weight: 500;
        border-radius: 4px;
    }
}