@import "../../../styles/mixins/global.scss";
.footer-second-grid {
    display: grid;
    grid-template-columns: 200px 1fr 1fr;
    padding: 0 0 40px 0;
    gap: 80px;
    border-bottom: 1px solid #d0d0d0;
    @include breakpoint(max-lg) {
        grid-template-columns: 1fr;
    }
    @include breakpoint(max-sm) {
        gap: 20px;
    }
    .footer-second-grid-items {
        .footer-contact-section {
            @include breakpoint(max-sm) {
                margin: 20px 0 0 0;
            }
            .details-alignment:last-child {
                padding: 0;
            }
            .details-alignment {
                gap: 8px;
                padding: 0 0 30px 0;
                a {
                    text-align: center;
                    display: block;
                    padding: 11px 0 0 0 !important;
                    color: var(--green-color) !important;
                }
                .img-alignment {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img {
                        max-width: 20px;
                    }
                    p {
                        padding-left: 10px;
                        @include text-sm;
                        color: var(--black-3);
                    }
                }
            }
        }
        .footer-sub-title {
            h2 {
                @include heading-4;
                color: var(--black-3);
                margin: 0 0 40px 0;
                font-size: 1rem;
                @include breakpoint("max-sm") {
                    text-align: center;
                    margin: 0 0 20px 0;
                }
            }
        }
        .footer-menu-alignment {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            div {
                @include breakpoint("max-sm") {
                    width: 100%;
                }
            }
            a {
                @include text-sm;
                color: var(--black-4);
                display: block;
                text-transform: uppercase;
                padding: 0 0 16px 0;
                cursor: pointer;
                @include breakpoint("max-sm") {
                    text-align: center;
                }
                &.number-alignment {
                    color: black;
                }
            }
        }
        .logo {
            padding: 0 0 20px 0;
            @include breakpoint("max-sm") {
                @include flex-center;
                justify-content: center;
            }
            img {
                max-width: 100%;
                object-fit: contain;
                height: 65px;
                cursor: pointer;
                @include pointer;
            }
        }
        .footer-child-text {
            p {
                @include text-sm;
                color: var(--black-4);
            }
        }
    }
}

.pdf-asas {
    font-family: "Inter", sans-serif;
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.5;
    letter-spacing: 0.02em;
    color: var(--black-3);
    margin: 0 0 40px 0;
    text-align: center;
}