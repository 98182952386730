@import '../../styles/mixins/global.scss';



.about-content-grid-alignment {
    padding: 100px 0 80px 0;

    @include breakpoint("max-md") {
        padding: 140px 0 60px 0;
    }

    @include breakpoint("max-sm") {
        padding: 60px 0;
    }

    .grid {
        display: grid;
        grid-template-columns: 450px 1fr;
        gap: 200px;
        align-items: center;

        @include breakpoint(max-md) {
            grid-template-columns: 1fr;
            gap: 100px;
        }

        @include breakpoint("max-sm") {
            gap: 40px;
        }

        .grid-items:nth-child(1) {
            @include breakpoint("max-sm") {
                order: 2;
            }
        }

        .grid-items:nth-child(2) {
            @include breakpoint("max-sm") {
                order: 1;
            }
        }

        .grid-items {
            h1 {
                @include heading-1;
                color: var(--green-color);
                max-width: calc(100% - 15%);
                margin: 0 0 16px 0;

                @include breakpoint("max-sm") {
                    @include heading-2;
                    margin: 0 0 14px 0;
                }
            }

            h2 {
                @include heading-3;
                color: var(--black-4);
                margin: 0 0 40px 0;

                @include breakpoint("max-sm") {
                    @include heading-4;
                    margin: 0 0 20px 0;
                }
            }

            .fill-button {
                padding: 40px 0 0 0;
            }

            img {
                width: 100%;
                height: 540px;
                object-fit: cover;

                @include breakpoint("max-sm") {
                    height: 440px;
                }
            }

            p {
                @include text;
                color: var(--black-4);
                max-width: calc(100% - 10%);

                @include breakpoint("max-sm") {
                    text-align: justify;
                    max-width: 100%;
                }
            }
        }
    }
}