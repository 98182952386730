@import "../../../styles/mixins/global.scss";
.product-details-section {
    padding: 100px 0 80px 0;
    @include breakpoint("max-sm") {
        padding: 60px 0 80px 0;
    }
    .grid {
        display: grid;
        grid-template-columns: 500px 1fr;
        gap: 100px;
        @include breakpoint("max-md") {
            grid-template-columns: repeat(1, 1fr);
            display: block;
        }
        .fill-button {
            padding: 20px 0 0 0;
            gap: 10px;
        }
        .grid-item:nth-child(1) {
            @include breakpoint("max-md") {
                padding: 0 100px;
            }
            @include breakpoint("max-sm") {
                padding: 0 0;
            }
        }
        .grid-item {
            .mySwiper {
                .swiper-wrapper {
                    .swiper-slide {
                        height: 500px;
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }
            }
            .product-img-alignment {
                cursor: pointer;
                img {
                    width: 100%;
                    border-radius: 4px;
                    display: block;
                }
            }
            .child-grid-alignment {
                display: grid;
                grid-template-columns: repeat(5, 1fr);
                gap: 10px;
                margin-top: 12px;
                .child-item-alignment {
                    img {
                        width: 100%;
                        height: 70px;
                        display: block;
                        border-radius: 4px;
                        cursor: pointer;
                        z-index: 999999999;
                    }
                    &:hover {
                        transform: translateX(0rem) scale(1.05);
                        transition: 0.2s all ease-in-out;
                    }
                }
            }
            .product-details-alignemt {
                ul {
                    margin: 0;
                    padding: 0;
                    li {
                        font-size: 14px;
                        line-height: 22px;
                    }
                }
                .product-name {
                    h4 {
                        font-family: "Inter", sans-serif;
                        font-size: 2rem;
                        font-weight: 600;
                        line-height: 1.5;
                        color: var(--black-3);
                        font-weight: 500;
                        margin: 0 0 16px 0;
                    }
                }
                .product-details {
                    p {
                        font-family: "Inter", sans-serif;
                        font-size: 1rem;
                        font-weight: 400;
                        line-height: 1.5;
                        letter-spacing: 0.02em;
                        color: var(--black-4);
                        max-width: 600px;
                        margin: 0 0 16px 0;
                        text-align: justify;
                    }
                }
                .price-tag-alignment {
                    @include flex-center;
                    gap: 10px;
                    margin: 0 0 10px 0;
                    .title {
                        span {
                            font-family: "Inter", sans-serif;
                            font-size: 1rem;
                            font-weight: 500;
                            line-height: 1.5;
                            color: var(--black-3);
                            font-weight: 500;
                            margin: 0 0 16px 0;
                        }
                    }
                    .price-alignment {
                        span {
                            font-family: "Inter", sans-serif;
                            font-size: 1rem;
                            font-weight: 400;
                            line-height: 1.5;
                            letter-spacing: 0.02em;
                            color: var(--black-4);
                        }
                        a {
                            font-family: "Inter", sans-serif;
                            font-size: 1rem;
                            font-weight: 400;
                            line-height: 1.5;
                            letter-spacing: 0.02em;
                            color: var(--green-color);
                        }
                        .sub-details-alignment {
                            span {
                                font-family: "Inter", sans-serif;
                                font-size: 1rem;
                                font-weight: 400;
                                line-height: 1.5;
                                letter-spacing: 0.02em;
                                color: var(--black-4);
                            }
                        }
                    }
                }
                .atrribute-tag-alignment {
                    .title {
                        span {
                            font-family: "Inter", sans-serif;
                            font-size: 1rem;
                            font-weight: 500;
                            line-height: 1.5;
                            color: var(--black-3);
                            font-weight: 500;
                            margin: 0 0 16px 0;
                        }
                    }
                    .price-alignment {
                        @include flex;
                        flex-direction: column;
                        gap: 5px;
                        padding: 12px 0 0 0;
                        .sub-details-alignment {
                            span {
                                font-family: "Inter", sans-serif;
                                font-size: 0.9rem;
                                font-weight: 500;
                                color: var(--black-3);
                                line-height: 1.5;
                                letter-spacing: 0.02em;
                            }
                        }
                    }
                }
            }
        }
    }
}

.related-product-alignment {
    margin: 50px 0;
    .page-title {
        padding: 0 0 80px 0;
        h1 {
            @include heading-1;
            color: var(--black-3);
            margin: 0;
            text-align: center;
        }
    }
    .product-grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 30px;
        .product-grid-items {
            position: relative;
            overflow: hidden;
            .image-blur {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: calc(100% - 3px);
                background-color: rgba(0, 0, 0, 0.5);
            }
            .card-content-alignment {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: flex;
                cursor: pointer;
                align-items: flex-end;
                div {
                    padding: 40px;
                    h1 {
                        @include heading-2;
                        color: var(--white-color);
                    }
                }
            }
            .card-image {
                position: relative;
                img {
                    height: 380px;
                    width: 100%;
                    object-fit: cover;
                }
            }
        }
    }
}

.right-slider-icon-alignment {
    position: absolute;
    top: 50%;
    right: -42px;
    @include breakpoint("max-sm") {
        right: -10px;
    }
    img {
        width: 30px;
        @include pointer;
        @include breakpoint("max-sm") {
            width: 18px;
        }
    }
}

.left-slider-icon-alignment {
    position: absolute;
    top: 50%;
    left: -42px;
    @include breakpoint("max-sm") {
        left: -10px;
    }
    img {
        width: 30px;
        @include pointer;
        @include breakpoint("max-sm") {
            width: 18px;
        }
    }
}

.slick-slide {
    img {
        width: 100% !important;
        height: 355px !important;
        object-fit: cover !important;
    }
}

$ig-small-screen: 768px !default;
$ig-xsmall-screen: 480px !default;
$ig-white: #fff !default;
$ig-black: #000 !default;
$ig-blue: #337ab7 !default;
$ig-background-black: rgba(0, 0, 0, 0.4) !default;
$ig-transparent: rgba(0, 0, 0, 0) !default;
$ig-shadow: 0 2px 2px lighten($ig-black, 10%);
@mixin vendor-prefix($name, $value) {
    @each $vendor in ("-webkit-", "-moz-", "-ms-", "-o-", "") {
        #{$vendor}#{$name}: #{$value};
    }
}

// SVG ICON STYLES
.image-gallery-icon {
    color: #4baf47;
    transition: all 0.3s ease-out;
    appearance: none;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    outline: none;
    position: absolute;
    z-index: 4;
    filter: drop-shadow($ig-shadow);
    @media (hover: hover) and (pointer: fine) {
        &:hover {
            color: #4baf47;
            .image-gallery-svg {
                transform: scale(1.1);
            }
        }
    }
    &:focus {
        // a11y support
        outline: 2px solid $ig-blue;
    }
}

.image-gallery-using-mouse {
    .image-gallery-icon {
        &:focus {
            outline: none;
        }
    }
}

.image-gallery-fullscreen-button,
.image-gallery-play-button {
    bottom: 0;
    padding: 20px;
    .image-gallery-svg {
        height: 28px;
        width: 28px;
    }
    @media (max-width: $ig-small-screen) {
        padding: 15px;
        .image-gallery-svg {
            height: 24px;
            width: 24px;
        }
    }
    @media (max-width: $ig-xsmall-screen) {
        padding: 10px;
        .image-gallery-svg {
            height: 16px;
            width: 16px;
        }
    }
}

.image-gallery-fullscreen-button {
    right: 0;
}

.image-gallery-play-button {
    left: 0;
}

.image-gallery-left-nav,
.image-gallery-right-nav {
    padding: 50px 10px;
    top: 50%;
    transform: translateY(-50%);
    .image-gallery-svg {
        height: 120px;
        width: 60px;
    }
    @media (max-width: $ig-small-screen) {
        .image-gallery-svg {
            height: 72px;
            width: 36px;
        }
    }
    @media (max-width: $ig-xsmall-screen) {
        .image-gallery-svg {
            height: 48px;
            width: 24px;
        }
    }
    &[disabled] {
        cursor: disabled;
        opacity: 0.6;
        pointer-events: none;
    }
}

.image-gallery-left-nav {
    left: 0;
}

.image-gallery-right-nav {
    right: 0;
}

// End of Icon styles
.image-gallery {
    @include vendor-prefix("user-select", none);
    -webkit-tap-highlight-color: $ig-transparent;
    position: relative;
    padding: 20px 0 0 0;
    &.fullscreen-modal {
        background: $ig-black;
        bottom: 0;
        height: 100%;
        left: 0;
        position: fixed;
        right: 0;
        top: 0;
        width: 100%;
        z-index: 5;
        .image-gallery-content {
            top: 50%;
            transform: translateY(-50%);
        }
    }
}

.image-gallery-content {
    position: relative;
    line-height: 0;
    top: 0;
    &.fullscreen {
        background: $ig-black;
    }
    .image-gallery-slide.center {
        height: auto !important;
    }
    .image-gallery-slide .image-gallery-image {
        max-height: calc(100vh - 130px); // 80 px for the thumbnail space
    }
    &.left,
    &.right {
        .image-gallery-slide .image-gallery-image {
            max-height: 100vh;
        }
    }
}

.image-gallery-slide-wrapper {
    position: relative;
    &.left,
    &.right {
        display: inline-block;
        width: calc(100% - 110px); // 100px + 10px for margin
        @media (max-width: $ig-small-screen) {
            width: calc(100% - 87px); // 81px + 6px for margin
        }
    }
    &.image-gallery-rtl {
        direction: rtl;
    }
}

.image-gallery-slides {
    line-height: 0;
    overflow: hidden;
    position: relative;
    white-space: nowrap;
    text-align: center;
}

.image-gallery-slide {
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    &.center {
        position: relative;
    }
    .image-gallery-image {
        width: 100%;
        object-fit: contain;
    }
    .image-gallery-description {
        background: $ig-background-black;
        bottom: 70px;
        color: $ig-white;
        left: 0;
        line-height: 1;
        padding: 10px 20px;
        position: absolute;
        white-space: normal;
        @media (max-width: $ig-small-screen) {
            bottom: 45px;
            font-size: 0.8em;
            padding: 8px 15px;
        }
    }
}

.image-gallery-bullets {
    bottom: 20px;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    width: 80%;
    z-index: 4;
    .image-gallery-bullets-container {
        margin: 0;
        padding: 0;
        text-align: center;
    }
    .image-gallery-bullet {
        appearance: none;
        background-color: transparent;
        border: 1px solid $ig-white;
        border-radius: 50%;
        box-shadow: $ig-shadow;
        cursor: pointer;
        display: inline-block;
        margin: 0 5px;
        outline: none;
        padding: 5px;
        transition: all 0.2s ease-out;
        @media (max-width: $ig-small-screen) {
            margin: 0 3px;
            padding: 3px;
        }
        @media (max-width: $ig-xsmall-screen) {
            padding: 2.7px;
        }
        &:focus {
            transform: scale(1.2);
            background: $ig-blue;
            border: 1px solid $ig-blue;
        }
        &.active {
            transform: scale(1.2);
            border: 1px solid $ig-white;
            background: $ig-white;
        }
        @media (hover: hover) and (pointer: fine) {
            &:hover {
                background: $ig-blue;
                border: 1px solid $ig-blue;
            }
            &.active:hover {
                background: $ig-blue;
            }
        }
    }
}

.image-gallery-thumbnails-wrapper {
    position: relative;
    &.thumbnails-swipe-horizontal {
        touch-action: pan-y;
    }
    &.thumbnails-swipe-vertical {
        touch-action: pan-x;
    }
    &.thumbnails-wrapper-rtl {
        direction: rtl;
    }
    &.left,
    &.right {
        display: inline-block;
        vertical-align: top;
        width: 100px;
        @media (max-width: $ig-small-screen) {
            width: 81px; // 75px + 6px for border
        }
        .image-gallery-thumbnails {
            height: 100%;
            width: 100%;
            left: 0;
            padding: 0;
            position: absolute;
            top: 0;
            .image-gallery-thumbnail {
                display: block;
                margin-right: 0;
                padding: 0;
                +.image-gallery-thumbnail {
                    margin-left: 0;
                    margin-top: 2px;
                }
            }
        }
    }
    &.left,
    &.right {
        margin: 0 5px;
        @media (max-width: $ig-small-screen) {
            margin: 0 3px;
        }
    }
}

.image-gallery-thumbnails {
    overflow: hidden;
    padding: 5px 0;
    @media (max-width: $ig-small-screen) {
        padding: 3px 0;
    }
    .image-gallery-thumbnails-container {
        cursor: pointer;
        text-align: center;
        white-space: nowrap;
    }
}

.image-gallery-thumbnail {
    display: inline-block;
    border: 4px solid transparent;
    transition: border 0.3s ease-out;
    width: 100px;
    background: transparent;
    padding: 0;
    @media (max-width: $ig-small-screen) {
        border: 3px solid transparent;
        width: 81px;
    }
    +.image-gallery-thumbnail {
        margin-left: 2px;
    }
    .image-gallery-thumbnail-inner {
        display: block;
        position: relative;
    }
    .image-gallery-thumbnail-image {
        vertical-align: middle;
        width: 100%;
        height: 70px;
        object-fit: cover;
        line-height: 0;
    }
    &.active,
    &:focus {
        outline: none;
        border: 4px solid $ig-blue;
        @media (max-width: $ig-small-screen) {
            border: 3px solid $ig-blue;
        }
    }
    @media (hover: hover) and (pointer: fine) {
        &:hover {
            outline: none;
            border: 4px solid $ig-blue;
            @media (max-width: $ig-small-screen) {
                border: 3px solid $ig-blue;
            }
        }
    }
}

.image-gallery-thumbnail-label {
    box-sizing: border-box;
    color: white;
    font-size: 1em;
    left: 0;
    line-height: 1em;
    padding: 5%;
    position: absolute;
    top: 50%;
    text-shadow: $ig-shadow;
    transform: translateY(-50%);
    white-space: normal;
    width: 100%;
    @media (max-width: $ig-small-screen) {
        font-size: 0.8em;
        line-height: 0.8em;
    }
}

.image-gallery-index {
    background: $ig-background-black;
    color: $ig-white;
    line-height: 1;
    padding: 10px 20px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 4;
    @media (max-width: $ig-small-screen) {
        font-size: 0.8em;
        padding: 5px 10px;
    }
}

.modal-close-alignment {
    position: absolute;
    top: 25px;
    right: 25px;
    z-index: 9999999999;
    cursor: pointer;
    svg {
        width: 35px;
        height: 35px;
        path {
            stroke: red;
        }
    }
}

.slider-modal {
    height: 100vh;
    position: fixed;
    top: 0;
    width: 100%;
    right: 0;
    background: white;
    z-index: 999999999;
}

.images-product-details-all-content {
    .image-gallery-content .image-gallery-slide .image-gallery-image {
        height: 300px !important;
        max-height: 300px !important;
    }
    .image-gallery-left-nav .image-gallery-svg,
    .image-gallery-right-nav .image-gallery-svg {
        height: 86px !important;
        width: 29px !important;
    }
    .image-gallery-slide .image-gallery-image {
        object-fit: cover !important;
    }
    .image-gallery-left-nav {
        padding: 20px 24px !important;
    }
    .image-gallery-play-button {
        padding: 34px 34px 25px 34px !important;
    }
}

.email-new0top-alignment {
    padding: 20px 0;
}

.assign-modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    @include flex-center;
    justify-content: center;
    z-index: 999;
    .assign-modal-box {
        position: relative;
        width: 850px;
        height: calc(100vh - 150px);
        overflow: auto;
        background-color: #fff;
        border-radius: 6px;
        // table {
        //     width: 100%;
        //     border-collapse: collapse;
        //     tr {
        //         border: 1px solid #d0d0d0;
        //     }
        //     td {
        //         border: 1px solid #d0d0d0;
        //         padding: 10px;
        //         font-size: 14px;
        //     }
        //     th {
        //         padding: 10px;
        //         font-size: 14px;
        //         border: 1px solid #d0d0d0;
        //     }
        // }
        // ul {
        //     margin: 0;
        //     padding: 0 1.25rem 0 1.25rem;
        //     li {
        //         font-size: 14px;
        //         padding: 0 0 10px 0;
        //     }
        // }
        .modal-body {
            padding: 20px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            .modal-divv {
                height: 100%;
                width: 100%;
                .iframewrapper {
                    height: 100vh;
                    width: 100%;
                }
            }
            .form-control {
                padding: 0 0 20px 0;
            }
            .blue-button {
                @include flex-center;
                justify-content: center;
                padding: 20px 0 0 0;
            }
        }
        .modal-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 20px;
            position: sticky;
            top: 0;
            z-index: 999;
            background: #fff;
            border-bottom: 1px solid #d0d0d0;
            i {
                font-size: 20px;
                color: var(--gray-6);
                @include pointer;
            }
            h1 {
                @include heading-4;
                color: var(--dark-black);
                margin: 0;
            }
        }
    }
}

.img-container {
    display: flex;
    justify-content: center;
    height: 250px;
    padding: 10px 0 30px 0;
}

.pdfViewerButton {
    position: sticky;
    bottom: 0;
    background: #fff;
    width: 100%;
    .downloadButton {
        padding: 10px 20px;
        gap: 10px;
    }
}