@use '../mixins/breakpoint.scss';

@mixin heading-large {
  font-family: 'Inter', sans-serif;
  font-size: 4.5rem;
  font-weight: 600;
  line-height: 1.1;
  letter-spacing: -0.02em;
}

@mixin heading-1 {
  font-family: 'Inter', sans-serif;
  font-size: 40px;
  font-weight: 600;
  line-height: 1.1;
  letter-spacing: -0.01em;

  @include breakpoint(max-sm) {
    font-size: 2rem;
    letter-spacing: -0.04em;
  }
}

@mixin heading-2 {
  font-family: 'Inter', sans-serif;
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.1;
}

@mixin heading-3 {
  font-family: 'Inter', sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.5;
}

@mixin heading-4 {
  font-family: 'Inter', sans-serif;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 0.02em;

  @include breakpoint(max-sm) {
    font-size: 1rem;
  }
}

@mixin heading-5 {
  font-family: 'Inter', sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.02em;
}

@mixin heading-5-bold {
  font-family: 'Inter', sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 0.02em;
}

@mixin heading-6 {
  font-family: 'Inter', sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.02em;
}

@mixin text-md {
  font-family: 'Inter', sans-serif;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.02em;

  @include breakpoint(max-sm) {
    font-size: 1rem;
  }
}

@mixin text {
  font-family: 'Inter', sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.02em;
}

@mixin text-bold {
  font-family: 'Inter', sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.02em;
}

@mixin text-sm {
  font-family: 'Inter', sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.02em;

  @include breakpoint(max-sm) {
    font-size: 0.8rem;
  }
}

@mixin text-sm-bold {
  font-family: 'Inter', sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.02em;
}