@mixin flex-center {
    display: flex;
    align-items: center;
}

@mixin flex-between {
    @include flex-center();
    justify-content: space-between;
}

@mixin flex-around {
    @include flex-around();
}

@mixin block {
    display: block;
}


@mixin flex {
    display: flex;
}

@mixin pointer {
    cursor: pointer;
}