@import "../../../styles/mixins/global.scss";

.expliore-project-content-alignment {
    padding: 90px 0;

    @include breakpoint("max-md") {
        padding: 60px 0;
    }

    @include breakpoint(max-sm) {
        padding: 60px 0;
    }

    .page-title {
        padding: 0 0 80px 0;

        @include breakpoint("max-sm") {
            padding: 0 0 60px 0;
        }

        h1 {
            @include heading-1;
            margin: 0 0 0 0;
            color: var(--green-color);
            text-align: center;
        }

        p {
            @include text-md;
            max-width: calc(900px);
            text-align: center;
            margin: 20px auto 0 auto;
            color: var(--black-4);

            @include breakpoint("max-sm") {
                text-align: justify;
            }
        }
    }

    // .mySwiper {
    // .swiper-wrapper {
    .slick-slide {
        padding: 0 20px;
    }

    .grid-items {
        height: 540px;
        position: relative;
        overflow: hidden;
        cursor: pointer;

        &:hover {
            .contnet-bottom-alignment {
                transform: translateY(0%);
                transition: .3s ease-in-out;
            }
        }

        .contnet-bottom-alignment {
            position: absolute;
            top: 0;
            background-color: rgba(0, 0, 0, 0.5);
            left: 0;
            width: 100%;
            height: 100%;
            transition: .3s ease-in-out;
            border-radius: 6px;
            transform: translateY(100%);
            display: flex;
            align-items: flex-end;

            div {
                padding: 25px;

                h3 {
                    @include heading-3;
                    color: var(--white-color);
                    margin: 0 0 16px 0;
                }

                p {
                    @include text-sm;
                    color: var(--white-color);
                }
            }
        }

        .card-image {
            position: relative;

            img {
                // width: 100%;
                width: 100%;
                height: 520px !important;
                position: relative;
                object-fit: cover;
                border-radius: 6px;
            }

            .card-blur {
                position: absolute;
                left: 0;
                top: 0;
                border-radius: 6px;
                // width: 100%;
                width: 100%;
                height: 1000% !important;
                background-image: linear-gradient(to top, rgba(31, 30, 23, 1), rgba(31, 30, 23, 0));
            }
        }
    }
}

// }
// }
// .grid {
//     display: grid;
//     grid-template-columns: repeat(4, 1fr);
//     gap: 40px;
//     @media only screen and (max-width: 1279px) {
//         grid-template-columns: repeat(3, 1fr);
//     }
//     @media only screen and (max-width:768px) {
//         grid-template-columns: repeat(2, 1fr);
//     }
//     .grid-items {
//         position: relative;
//         .contnet-bottom-alignment {
//             position: absolute;
//             top: 0;
//             left: 0;
//             width: 100%;
//             height: 100%;
//             display: flex;
//             align-items: flex-end;
//             div {
//                 padding: 25px;
//                 h3 {
//                     @include heading-3;
//                     color: var(--white-color);
//                     margin: 0 0 16px 0;
//                 }
//                 p {
//                     @include text-sm;
//                     color: var(--white-color);
//                 }
//             }
//         }
//         .card-image {
//             position: relative;
//             img {
//                 width: 100%;
//                 height: 470px;
//                 position: relative;
//                 object-fit: cover;
//                 border-radius: 6px;
//             }
//             .card-blur {
//                 position: absolute;
//                 bottom: 0;
//                 left: 0;
//                 border-radius: 6px;
//                 width: 100%;
//                 height: 270px;
//                 background-image: linear-gradient(to top, rgba(31, 30, 23, 1), rgba(31, 30, 23, 0));
//             }
//         }
//     }
// }\
.swiper-button-next {

    // left: 10px !important;
    // transform: translateX(-220px) !important;
    // right: auto !important;
    // position: fixed !important;
    &::after {
        color: #4baf47 !important;
    }
}

.swiper-button-prev {

    // left: 10px !important;
    // transform: translateX(220px) !important;
    // right: auto !important;
    // position: fixed !important;
    &::after {
        color: #4baf47 !important;
    }
}

.leftArrow {
    position: absolute;
    top: 50%;
    right: -42px;

    @include breakpoint("max-md") {
        right: -10px;
    }

    @include breakpoint("max-sm") {
        display: none;
    }

    img {
        width: 30px;
        @include pointer;

        @include breakpoint("max-md") {
            width: 18px;
        }
    }
}

.rightArrow {
    position: absolute;
    top: 50%;
    left: -42px;

    @include breakpoint("max-md") {
        left: -10px;
    }

    @include breakpoint("max-sm") {
        display: none;
    }

    img {
        width: 30px;
        @include pointer;

        @include breakpoint("max-md") {
            width: 15px;
        }
    }
}