@import '../../styles/mixins/global.scss';
.product-banner-style {
    background-image: url("../../assets/images/contact-banner.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    margin: 76px 0 0 0;
    background-position: center;
    height: 400px;
    @include flex-center;
    justify-content: center;
    position: relative;
    .page-title {
        position: relative;
        h1 {
            @include heading-1;
            color: var(--white-color);
            text-align: center;
            margin: 0 0 24px 0;
        }
    }
    &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: "";
        background-color: rgba(0, 0, 0, 0.5);
    }
}

.product-details-section-content-alignment {
    padding: 100px 0 80px 0;
    @include breakpoint("max-md") {
        padding: 140px 0 60px 0;
    }
    @include breakpoint("max-sm") {
        padding: 60px 0;
    }
    .page-title {
        padding: 0 0 60px 0;
        @include breakpoint("max-sm") {
            padding: 0 0 40px 0;
        }
        h1 {
            @include heading-1;
            color: var(--green-color);
            margin: 0;
            text-align: center;
            @include breakpoint("max-sm") {
                @include heading-2;
            }
        }
    }
    .grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 30px;
        @include breakpoint(max-md) {
            grid-template-columns: repeat(2, 1fr);
        }
        @include breakpoint(max-sm) {
            grid-template-columns: 1fr;
        }
        .grid-items {
            border: 1px solid #d0d0d0;
            border-radius: 4px;
            padding: 12px;
            .card-content-alignment {
                padding: 10px 0;
                div {
                    h1 {
                        @include heading-4;
                        color: var(--black-3);
                        margin: 0;
                    }
                }
            }
            .card-image {
                position: relative;
                img {
                    height: 310px;
                    border-radius: 4px;
                    width: 100%;
                    object-fit: cover;
                }
            }
        }
    }
}