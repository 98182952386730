@import '../../../styles/mixins/global.scss';

.hero-section-alignment {
    position: relative;

    .video-relative {
        position: relative;



        img {
            width: 100%;

            height: 721px;

            object-fit: cover;

            @include breakpoint("max-sm") {
                height: calc(100vh - 92px);
                object-fit: cover;
                object-position: right;
            }

        }


        .video-content-alignment {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            @include flex-center;

            @include breakpoint("max-sm") {
                display: block;
                padding: 60px 0 0 0;
            }

            .hero-text-style {
                position: relative;

                .fill-button {
                    padding: 30px 0 0 0;

                    @include breakpoint("max-sm") {
                        display: flex;
                        justify-content: center;
                        padding: 0px 0 0 0;

                    }
                }

                p {
                    @include text-md;
                    @include font-bold;
                    margin: 0 0 24px 0;
                    color: var(--green-color);

                    @include breakpoint("max-sm") {
                        text-align: center;
                        margin: 0 0 16px 0;
                    }
                }

                h2 {
                    @include heading-1;
                    color: #1C1C1C;
                    width: fit-content;
                    max-width: 610px;
                    margin: 0 0 24px 0;
                    line-height: 1.3;

                    @include breakpoint("max-sm") {
                        text-align: center;
                        font-size: 23px !important;
                        max-width: 100% !important;
                    }

                }

                span {
                    @include text;
                    color: var(--white-color);
                    display: block;
                    max-width: calc(600px);
                }
            }
        }
    }
}

.line-1 {
    position: relative;
    top: 50%;
    margin: 24px auto 0 auto;
    border-right: 2px solid rgba(255, 255, 255, .75);
    font-size: 180%;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    transform: translateY(-50%);
}

.anim-typewriter {
    animation: typewriter 4s steps(44) 1s infinite normal both
}

@keyframes typewriter {
    from {
        width: 0;
    }

    to {
        width: 100%;
    }
}

.mobile-none {
    @include breakpoint("max-sm") {
        display: none;
    }
}

.mobile-show {
    display: none;

    @include breakpoint("max-sm") {
        display: block;
    }
}